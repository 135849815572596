import * as React from "react";
import * as classNames from "classnames";

import { DynamicWidgetBaseProps } from "../dynamicWidget.types";
import { ErrorBoundary } from "../../../components/ErrorBoundary";
import LocalizedTitleAndLabel from "../../../components/widgetTitleAndLabel/LocalizedLableTitle";
import { State } from "../../../redux";
import { WidgetOptions } from "./";
import { getI18nLocaleString } from "../../../i18n";
import { getLocalizedContent } from "../../../utils/localizedContent.util";
import namespaceList from "../../../i18n/namespaceList";
import { useBarcode } from "next-barcode";
import { useSelector } from "react-redux";

interface BarcodeWidgetProps extends DynamicWidgetBaseProps<WidgetOptions> {}

interface BarcodeBaseProps {
    input: string;
    height?: number;
    allowDownload: boolean;
    downloadButtonColor?: string;
}

function BarcodeWidget(props: BarcodeWidgetProps): JSX.Element {
    const { context, options } = props;
    const { currentLocale, site } = context;
    const { allowDownload, barcodeHeight, downloadButtonColor, enableWidgetTitle, fontColor, styleWidgetTitle, useTitleHeadings } = options;
    const localizedWidgetTitle: string =
        (options.enableWidgetTitle && getLocalizedContent({ site, currentLocale, localizedContent: options.localizedWidgetTitle || [], keys: ["widgetTitleText"] })?.widgetTitleText) || "";

    // Add conditions below to change 'input' based on future options added to the widget
    // customerId should be the default value if no options are selected
    const myEnvState = useSelector((state: State) => state.myEnvState);
    const customerId: string = myEnvState.mainCustomer?.customerId.toString() || "00000000";
    const input: string = customerId;

    return (
        <ErrorBoundary>
            <div
                className={classNames("barcode-widget", { ["background-color-" + options.barcodeBackgroundColor]: options.barcodeBackgroundColor?.includes("theme") })}
                style={{ background: (options.barcodeBackgroundColor?.includes("rgba") && options.barcodeBackgroundColor) || undefined }}
            >
                <div className="barcode-widget__title">
                    <LocalizedTitleAndLabel
                        localizedTitle={localizedWidgetTitle}
                        enableWidgetTitle={enableWidgetTitle}
                        useTitleHeadings={useTitleHeadings}
                        styleWidgetTitle={styleWidgetTitle}
                        className={classNames("widget-heading")}
                        style={classNames(fontColor?.includes("rgba") && fontColor)}
                    />
                </div>
                <BarcodeBase input={input} height={barcodeHeight} allowDownload={allowDownload} downloadButtonColor={downloadButtonColor} />
            </div>
        </ErrorBoundary>
    );
}

function BarcodeBase(props: BarcodeBaseProps): JSX.Element {
    const [downloadUrl, setDownloadUrl] = React.useState<string>("");

    const { inputRef } = useBarcode({
        value: props.input,
        options: {
            background: "white",
            height: props.height || 50,
            margin: 20,
        },
    });

    React.useEffect(() => {
        setDownloadUrl(inputRef.current?.src || "");
    }, [inputRef, props.input]);

    return (
        <div>
            <div className="barcode-widget__code">
                <img className="barcode-widget__code-image" ref={inputRef} alt="customer-barcode" />
            </div>
            {props.allowDownload && props.downloadButtonColor && (
                <div className="barcode-widget__download-button">
                    <a
                        className={classNames("btn btn-secondary", { ["background-color-" + props.downloadButtonColor]: props.downloadButtonColor?.includes("theme") })}
                        style={{ background: (props.downloadButtonColor?.includes("rgba") && props.downloadButtonColor) || undefined }}
                        download="barcode-image.png"
                        href={downloadUrl}
                    >
                        <span className="barcode-widget__download-button-label">{getI18nLocaleString(namespaceList.widgetBarcode, "downloadButton")}</span>
                    </a>
                </div>
            )}
        </div>
    );
}

export default BarcodeWidget;
