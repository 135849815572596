import * as React from "react";

import { FormSpec, multiSelectStylePicker } from "../../../form-specs";
import { LocalizedTitleOptions, getWidgetTitleOptions, titleStylingOptions } from "../../../components/widgetTitleAndLabel/localizedLableTitle.util";

import BarcodeWidget from "./Barcode";
import { PageWidgetSpec } from "../../widget";
import { WidgetGroup } from "../../widget.enum";
import { findMultiSelectStyleClassNames } from "../../../themes";
import { getI18nLocaleObject } from "../../../i18n";
import namespaceList from "../../../i18n/namespaceList";

export interface WidgetOptions extends LocalizedTitleOptions {
    allowDownload: boolean;
    styleIds: string[];
    barcodeHeight?: number;
    barcodeBackgroundColor?: string;
    downloadButtonColor?: string;
}

const TARGETS = ["barcode"];

export const widgetOptionsForm: FormSpec<WidgetOptions> = {
    id: "barcode-widget",
    name: getI18nLocaleObject(namespaceList.widgetBarcode, "barcodeWidgetName"),
    pluralName: getI18nLocaleObject(namespaceList.widgetBarcode, "barcodeWidgetsName"),
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.admin, "general"),
                    properties: [
                        [
                            ...getWidgetTitleOptions<WidgetOptions>(),
                            {
                                variable: "allowDownload",
                                label: getI18nLocaleObject(namespaceList.widgetBarcode, "allowDownload"),
                                type: "checkbox",
                                default: false,
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "style"),
                    properties: [
                        [
                            multiSelectStylePicker("styleIds", TARGETS),
                            ...titleStylingOptions<WidgetOptions>(),
                            {
                                variable: "barcodeHeight",
                                label: getI18nLocaleObject(namespaceList.widgetBarcode, "barcodeHeight"),
                                type: "number",
                                default: 50,
                            },
                            {
                                variable: "barcodeBackgroundColor",
                                label: getI18nLocaleObject(namespaceList.widgetBarcode, "barcodeBackgroundColor"),
                                type: "dual-color",
                                default: "#FFFFFF",
                            },
                            {
                                variable: "downloadButtonColor",
                                label: getI18nLocaleObject(namespaceList.widgetBarcode, "downloadButtonColor"),
                                type: "dual-color",
                                default: "#000000",
                                visible: (options: WidgetOptions) => !!options.allowDownload,
                            },
                        ],
                    ],
                },
            ],
        },
    ],
};

export const barcodeWidget: PageWidgetSpec<WidgetOptions> = {
    id: "BarcodeWidget",
    type: "page",
    widgetGroup: WidgetGroup ? WidgetGroup.MY_ENVIRONMENT : 7,
    name: getI18nLocaleObject(namespaceList.widgetBarcode, "barcodeWidgetName"),
    description: getI18nLocaleObject(namespaceList.widgetBarcode, "barcodeWidgetDescription"),
    optionsForm: widgetOptionsForm,
    defaultOptions: (): WidgetOptions => ({
        allowDownload: false,
        barcodeHeight: 50,
        barcodeBackgroundColor: "#FFFFFF",
        downloadButtonColor: "#000000",
        styleIds: [],
    }),
    async render(widget, context) {
        const className = findMultiSelectStyleClassNames(context.theme, TARGETS, widget.options.styleIds);
        return <BarcodeWidget options={widget.options} context={context} className={className} />;
    },
};
